@import url('https://fonts.googleapis.com/css?family=Josefin+Slab|Kranky');

$primary:#3b9243; /* MAIN COLOR */
$secondary: #b0d577; /* SECONDARY COLOR */
$blk: #000; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body{
	font-family: 'Josefin Slab', serif;


	font-weight: normal;
}
a {
	font-family: 'Josefin Slab', serif;

}
h1,h2,h3,h4,h5,h6 {
	font-family: 'Kranky', cursive;
}
.top-pad {
	font-size: 1.5em;
	a, p, li, ul, h1, h2, h3, h4 , h5, h6 {
		font-size: 1em;
	}
}

.navbar-default{
	background: darken($blk, 30%);
	border: none;
}
nav {
	z-index: 1000;
	background: $blk;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;
		color: lighten($wht, 20%);
		outline: none;

		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
				line-height: 0;
		}
	}
}

.navbar-default .navbar-nav>li>a:focus, {
	color: $wht;
}
.navbar-default .navbar-nav>li>a:hover {
	color: $primary;
} 

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}

.navbar-right {font-size: 2em;

	@media (max-width: 1024px) {
		font-size: 1.25em;
	}

	@media (max-width: 767px) {
		font-size: 1.5em;
	}

}

.nav .nav-divider {margin: 3px;}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


.logo{ width: 100%;
	   display: block;
	   margin: 0 auto;
	   max-width: 275px;
	   margin-top: 15px;
	   margin-left: 15px;

	@media(max-width: 1024px){
		max-width: 180px;
		margin-top: 25px;
		margin-left: 5px;
	}

	@media(max-width: 767px){
		max-width: 150px;
		margin-top: 10px;
		margin-left: 5px;
	}
}
h1, h2, h3   {
	font-size: 1.5em;
}

h1 {
	font-weight: bold;
	color: $wht;
	font-size: 4em;
	@media (max-width: 767px){
		font-size: 3em;
	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $blk;
	border-radius: $border-radius;
	background: $blk;
	color: $wht !important;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($blk,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $wht;
	border: none;

	&:hover {
		background: $wht;
		color: $blk;
		border: none;
	}
}
.modal-dialog {
	max-width: 250px;
	text-align: center;
	margin: 6em auto;

	label, button, .modal-title {
		font-size: 1.5em;
	}
	.close {display: none;
	}
	.modal-content {
		color: $blk;
		h2 {
			text-align:center;
		}
	}

	button {
		background: $blk;
		color: $wht;
		display: block;
		border:none;
		// padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-dialog input {    
	border: none;
    border-bottom: 1px solid #333333;
    text-align: center;
    color: #000 !important;}

.modal-header, .modal-footer {
	background:$blk;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: lighten($blk, 5%);
	color: $footerLinks;
	p {
		font-size: 1.2em;
	}
	a {
		font-size: 1em;
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

section {
	background-size: cover;
	background-repeat: no-repeat;;
	background-position:  center;
}

.hero-banner {
	background-image:url('../img/bg1.jpg');
	padding: 350px 0;
	margin-top: 90px;



	p {
		font-family: 'Dosis', sans-serif;
		font-style: italic;
		font-size: 2em;
		color: darken($wht, 15%);
	}
	@media (max-width: 1024px){
		padding: 150px 0;
		background-position: 10% 30%;
	}
	@media (max-width: 767px){
		padding: 70px 0;
		margin-top: 55px;
		background-position: 10% 50%;
	}
}

.trio-section {
	background-color:darken($wht, 15%);
	color: $blk;
	padding: 50px 0;
	h1 {
		color:$blk;
		text-transform: capitalize;
		padding-bottom: 50px;
	}
	h3 {
		font-size: 2em;
		padding-bottom: 25px
	}
	.cta {
		background: $blk;
		&:hover{
			color: $wht;
			background: darken($wht,60%);
			transition: all 0.7s ease 0s;

		}
	}
	@media(max-width: 767px){
		padding: 80px 0;
	}
}
.form {
	background: $wht;
	padding: 20px 0;
}
.box-info {
	background-image: url('../img/bg2.jpg');
	padding:350px 0;
	background-position: 50% 0%;


	@media(max-width: 767px){
		padding: 100px 0 100px 0;
	}
	@media(min-width: 768px) and (max-width: 1024px){
		padding: 40px 0 60px 0;
	}
	h1 {
		color: $wht;
		font-size: 4em;
		@media (max-width: 767px){
			font-size: 3em
		}
	}
	h2 {
		color: $blk;
		padding: 45px 0;
	}
	h3 {
		font-size: 3em;
		font-family: 'Dancing Script', cursive;
		color: darken($wht, 20%);
		padding-bottom: 35px;
	}
	p {
		font-size:2em;
		line-height: 1em;
		text-align: center;
		color: $wht;
		margin-top: 25px;
		@media (max-width: 767px){
			font-size: 2em;
		}
	}

}
.box {
	padding:100px 0;
	background: $blk;
	@media(max-width: 767px){
		padding: 50px 0;
	}
	p {
		font-size:2em;
		line-height: 1em;
		text-align: center;
		color: $wht;
		margin-top: 25px;
		@media (max-width: 767px){
			font-size: 2em;
		}
	}
	.cta {
		background: $blk;
		color: $wht;
		border-radius: 90px;
		border: 1px solid $wht;
	}
}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $blk;
    border-color: $wht;
	   color: $wht;
padding: 10px 2px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: grey;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}



.terms, .privacy {margin-top: 150px;

	@media (max-width: 767px) {
		margin-top: 100px;
	}
}

.terms h3 {font-weight: bold;
			font-size: 2em;}

.privacy h3 {font-weight: bold;
			font-size: 2em;}